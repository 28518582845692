.cc-modal__body-open {
  overflow: hidden;
}

.cc-modal__overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 250ms ease-in-out;
}

.ReactModal__Overlay--after-open.cc-modal__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  overflow:auto;
}

.ReactModal__Overlay--before-close.cc-modal__overlay {
  background-color: rgba(0, 0, 0, 0.0);
}

.cc-modal {
  flex: none;
  min-width: 25em;
  background: white;
  border-radius: 0.125em;
  outline: none;
  overflow: auto;
  flex-direction: column;
  transition: transform 250ms ease-in-out;
  transform: translate(0, calc(50vh + 50%));
  max-height: 90%;
  z-index:1;
}

.ReactModal__Content--after-open.cc-modal {
  transform: translate(0, 0);
}

.ReactModal__Content--before-close.cc-modal {
  transform: translate(0, calc(50vh + 50%));
}

.cc-modal-special {
  flex: none;
  min-width: 25em;
  /*background: white;*/
  border-radius: 0.125em;
  outline: none;
  /* overflow: auto; */
  flex-direction: column;
  transition: transform 250ms ease-in-out;
  transform: translate(0, calc(50vh + 50%));
  height: 0;
  z-index:1;
  /* max-height:0; */
}

.ReactModal__Content--after-open.cc-modal-special {
  transform: translate(0, -32vh);
}

.ReactModal__Content--before-close.cc-modal-special {
  transform: translate(0, calc(50vh + 50%));
}